import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Casa Del Ritmo
			</title>
			<meta name={"description"} content={"Experience the heartbeat of Havana at Casa Del Ritmo - where every sip and sound echoes the heart of Cuba"} />
			<meta property={"og:title"} content={"About | Casa Del Ritmo"} />
			<meta property={"og:description"} content={"Experience the heartbeat of Havana at Casa Del Ritmo - where every sip and sound echoes the heart of Cuba"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					About us
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Where the soul of Cuba mingles with timeless tales
				</Text>
				<Text font="--base">
					Unveil the legacy behind Casa Del Ritmo and discover the essence that makes our bar a unique beacon of Cuban spirit.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					About Casa Del Ritmo
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Nestled in the crossroads of past and present, Casa Del Ritmo stands as a testament to the rich tapestry of Cuban culture. We're more than just a bar – we're a bridge that connects the old-world charm of Cuba with the dynamism of the modern age.
					<br />
					<br />
					Our establishment draws inspiration from the vibrant streets of Havana during the golden age, a time when music was the language and rhythm was the heartbeat. Although we don't pinpoint a specific location or founder, our roots trace back to those serendipitous gatherings where stories flowed as freely as the rum.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Every corner of Casa Del Ritmo is imbued with a piece of Cuban history. From the handpicked vinyl records that play melodies of yesteryears to the aged rum that dances in our signature cocktails, everything here is a nod to the rich Cuban heritage.
					<br />
					<br />
					We take pride in maintaining an authentic atmosphere while offering a contemporary touch, ensuring that our patrons experience the best of both worlds. At Casa Del Ritmo, we believe in forging connections, celebrating life, and letting the Cuban spirit resonate in every shared moment.
				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Why Choose Casa Del Ritmo?
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Authentic Essence
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Experience the vibrant streets of Havana with our meticulously curated ambiance and genuine Cuban vibes.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Signature Flavors
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Indulge in unique cocktails crafted with a mix of tradition and innovation, offering tastes unparalleled and unforgettable.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Vinyl Vibes
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Relive the golden age with our exclusive Vinyl Corner, playing classic Cuban rhythms in a nostalgically electric setting.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Dynamic Experience
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					With live music nights and spontaneous Cuban-style performances, there's always a fresh rhythm to discover.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Inclusivity at its Best
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					At Casa Del Ritmo, every guest is family. Immerse in an environment where cultures blend and the Cuban spirit is celebrated by all.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13:29:53.264Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
					srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_3.jpg?v=2023-10-23T13%3A29%3A53.264Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Join us, as we continue to write new chapters in our story, honouring the past and welcoming the future, one glass at a time.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Visit Us
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});